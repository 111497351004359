import "./App.css";
import img1 from "./img1.png";
import img56 from "./345.png";
import Jagged from "./Jagged";
import Jagged2 from "./Jagged2";
import Marquee from "react-fast-marquee";
import img2 from "./123.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from "react";
import Slideshow from "./Slideshow";

function App() {
  const [copied, setCopied] = useState(false);

  const address = "Ecnb7pfVUe4dAaE4SC9iEn9Dr96cSx5G91nASvePszeB";
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const text = `  HI, I'M Niki *** Niki IS The cute chihuahua *** THE MOST MEMEABLE
  TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN VENTURE INTO THE
  CRYPTO COSMOS *** 0% TAX *** LP BURN *** REVOKED** HI, I'M Niki
  *** Niki IS The cute chihuahua *** THE MOST MEMEABLE TOKEN *** BUY
  Niki NOW *** NO BULLSHIT *** JOIN VENTURE INTO THE CRYPTO COSMOS
  *** 0% TAX *** LP BURN *** REVOKED** HI, I'M Niki *** Niki IS The
  cute chihuahua *** THE MOST MEMEABLE TOKEN *** BUY Niki NOW *** NO
  BULLSHIT *** JOIN VENTURE INTO THE CRYPTO COSMOS *** 0% TAX *** LP
  BURN *** REVOKED** HI, I'M Niki *** Niki IS The cute chihuahua ***
  THE MOST MEMEABLE TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN
  VENTURE INTO THE CRYPTO COSMOS *** 0% TAX *** LP BURN ***
  REVOKED** HI, I'M Niki *** Niki IS The cute chihuahua *** THE MOST
  MEMEABLE TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN VENTURE
  INTO THE CRYPTO COSMOS *** 0% TAX *** LP BURN *** REVOKED** HI,
  I'M Niki *** Niki IS The cute chihuahua *** THE MOST MEMEABLE
  TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN VENTURE INTO THE
  CRYPTO COSMOS *** 0% TAX *** LP BURN *** REVOKED** HI, I'M Niki
  *** Niki IS The cute chihuahua *** THE MOST MEMEABLE TOKEN *** BUY
  Niki NOW *** NO BULLSHIT *** JOIN VENTURE INTO THE CRYPTO COSMOS
  *** 0% TAX *** LP BURN *** REVOKED** HI, I'M Niki *** Niki IS The
  cute chihuahua *** THE MOST MEMEABLE TOKEN *** BUY Niki NOW *** NO
  BULLSHIT *** JOIN VENTURE INTO THE CRYPTO COSMOS *** 0% TAX *** LP
  BURN *** REVOKED** HI, I'M Niki *** Niki IS The cute chihuahua ***
  THE MOST MEMEABLE TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN
  VENTURE INTO THE CRYPTO COSMOS *** 0% TAX *** LP BURN ***
  REVOKED** HI, I'M Niki *** Niki IS The cute chihuahua *** THE MOST
  MEMEABLE TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN VENTURE
  INTO THE CRYPTO COSMOS *** 0% TAX *** LP BURN *** REVOKED** HI,
  I'M Niki *** Niki IS The cute chihuahua *** THE MOST MEMEABLE
  TOKEN *** BUY Niki NOW *** NO BULLSHIT *** JOIN VENTURE INTO THE
  CRYPTO COSMOS *** 0% TAX *** LP BURN *** REVOKED** HI, I'M Niki
  *** Niki IS The cute chihuahua *** THE MOST MEMEABLE TOKEN *** BUY
  Niki NOW *** NO BULLSHIT *** JOIN VENTURE INTO THE CRYPTO COSMOS
  *** 0% TAX *** LP BURN *** REVOKED**`;
  return (
    <div className="center">
      <div className="centered-text">Niki</div>
      <div className="web-text">Niki Ozeri CTO.</div>
      <div className="text-content">
        <div className="buttons" style={{ marginTop: "50px" }}>
          <a
            href="https://raydium.io/swap/?inputMint=sol&outputMint=Ecnb7pfVUe4dAaE4SC9iEn9Dr96cSx5G91nASvePszeB"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="skewed-button">BUY $NIKI</button>
          </a>
          <a
            href="https://t.me/nikiozerisolana"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="skewed-button">Telegram</button>
          </a>
          <a
            href="https://dexscreener.com/solana/HxUwku6Hfv7ywVdjgUBDQPSb39cc1vBwUfJywHNtpW2S"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="skewed-button">Chart</button>
          </a>
        </div>
      </div>
      <div className="section-2">
        <div>
          <img className="image1" src={img56} />
        </div>
        <div className="vertical-text">
          {" "}
          <div className="centered-text2">WE ARE Niki</div>
          <div className="web-text2">
            Meet Niki, the TikTok sensation known for her grumpy yet hilarious
            behavior! With the loving support of her owner Eviatar Ozeri, Niki
            has captured hearts worldwide with her adorable and funny videos.
          </div>
        </div>
      </div>
      <Slideshow />
      <button
        disabled={true}
        style={{ marginTop: "20px", marginBottom: "40px" }}
        className="skewed-button"
      >
        Create your own niki meme
      </button>
      <Jagged />
      <div
        className="bg1"
        style={{
          width: "100%",
          backgroundColor: "#000000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="bg2">
          <Marquee>
            <div className="marquee-text">{text}</div>
          </Marquee>
        </div>
      </div>
      <Jagged2 />
      <div className="section-2">
        <div className="vertical-text">
          {" "}
          <div className="centered-text2">NIKINOMICS</div>
          <div className="web-text2">
            liquidity is burnt. There is no tax there is no meme, just $NIKI.
            <span className="address">{address}</span>
            <CopyToClipboard text={address} onCopy={handleCopy}>
              <button style={{ marginTop: "20px" }} className="skewed-button">
                {copied ? "Copied!" : "Copy"}
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <div>
          <img className="image1" src={img2} />
        </div>
      </div>{" "}
      <Jagged />
      <div
        className="bg1"
        style={{
          width: "100%",
          backgroundColor: "#000000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="bg2">
          <Marquee>
            <div className="marquee-text">{text}</div>
          </Marquee>
        </div>
      </div>
      <Jagged2 />
      <Jagged />
      <div className="final">
        <div className="section-2">
          <div>
            <img className="image1" src={img1} />
          </div>
          <div className="vertical-text">
            {" "}
            <div className="centered-text3">SOCIAL</div>
            <div className="web-text2">JOIN Niki COMMUNITY</div>
            <div className="buttons" style={{ marginTop: "50px" }}>
              <a
                href="https://raydium.io/swap/?inputMint=sol&outputMint=Ecnb7pfVUe4dAaE4SC9iEn9Dr96cSx5G91nASvePszeB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="skewed-button">BUY $NIKI</button>
              </a>
              <a
                href="https://t.me/nikiozerisolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="skewed-button">Telegram</button>
              </a>
              <a
                href="https://x.com/nikionsolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="skewed-button">X</button>
              </a>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="copyright">Copyright © 2024 Niki</div>
    </div>
  );
}

export default App;
