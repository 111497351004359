// Slideshow.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

const Slideshow = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const images = [
    "https://i.imgur.com/vqKNCzi.jpeg",
    "https://i.imgur.com/DPV7RKh.jpeg",
    "https://i.imgur.com/k9zZ8K2.jpeg",
    "https://i.imgur.com/MBlSrIE.jpeg",
    "https://i.imgur.com/dqDtMry.jpeg",
    "https://i.imgur.com/PZOzIkA.jpeg",
    "https://i.imgur.com/kwBWZZ3.jpeg",
    "https://i.imgur.com/0qxm4oZ.jpeg",
    "https://i.imgur.com/0j2mMYB.jpeg",
    "https://i.imgur.com/FfjJTvR.jpeg",
    "https://i.imgur.com/lvYrwfe.jpeg",
    "https://i.imgur.com/PyByBcu.jpeg",
    "https://i.imgur.com/0z6ROuR.jpeg",
  ];

  return (
    <div
      className="slideshow"
      style={{ marginTop: "50px", borderRadius: "20px", marginBottom: "50px" }}
    >
      <div className="slider desktop-only">
        {" "}
        <Slider {...settings}>
          {images.map((url, index) => (
            <div key={index}>
              <img
                style={{
                  borderRadius: "10px",
                  height: "450px",
                  width: "450px",
                }}
                src={url}
                alt={`Slide ${index + 1}`}
                className="slideshow-image"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="slider mobile-only">
        <Slider {...settings}>
          {images.map((url, index) => (
            <div key={index}>
              <img
                style={{
                  borderRadius: "10px",
                  height: "250px",
                  width: "250px",
                }}
                src={url}
                alt={`Slide ${index + 1}`}
                className="slideshow-image"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Slideshow;
