import React from "react";

const Jagged = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3990.2 277.1"
      preserveAspectRatio="none"
      style={{ transform: "rotate(180deg)" }}
    >
      <path
        opacity="0.7"
        enable-background="new"
        d="M0.1,197.8c12.1,1.7,23,3.5,33.8,5.3c0.9,0.1,1.9-0.1,2.6-0.8
c6.5-6.2,17.2-9.5,27.2-8.2c0.3,0,0.7,0.1,1,0.3c1.6,0.7,3.1,1.5,4.7,2.2c1,0.5,2.3,0.3,3.2-0.4c11.5-8.5,35.2,1,46.8-7.3
c1-0.7,2.3-0.7,3.3-0.1c11.8,7.4,28.3,10.1,42.8,6.8c1.2-0.3,2.4,0.3,3.2,1.3c3.7,5.1,13.4,6,21.3,5.7c7.7-0.3,17.2,0.1,21.6,4.7
c1,1.1,2.7,1.2,3.9,0.4c1.2-0.8,2.3-1.6,3.5-2.3c1.3-0.9,3-0.6,4,0.5c4.7,5.6,12.2,9.6,20.5,10.9c2.4,0.4,4.9,0.6,6.7,1.9
c2.7,1.9,2.6,5.3,4.2,7.9c2.6,4.2,10.5,4.9,14.8,2c1-0.7,2.4-0.7,3.4,0.1c0.6,0.4,1.1,0.8,1.7,1.2c1.3,0.9,3,0.7,4.1-0.5
c1.1-1.2,2.1-2.4,3.2-3.6c1-1.2,2.7-1.4,4-0.5c1.9,1.3,3.9,2.6,5.9,3.9c1.5,1,3.6,0.5,4.4-1.2c2.9-5.9,11-10.1,18.9-9.2
c9.7,1.1,18.7,8.6,27.4,8.8c2.3,0.1,3.8,2.6,2.6,4.6c0,0,0,0.1-0.1,0.1c-1.2,2,0.3,4.5,2.6,4.6c7.7,0.2,15.3,3.5,19.3,8.8
c2.2,2.9,4.9,7,9.1,6.3c2.4-0.4,3.9-2.2,5.7-3.5c5.5-3.7,14.8-0.6,16.8,4.5c0.6,1.5,2.2,2.3,3.7,1.9c4.8-1.4,9.7-2.8,14.6-4.1
c1-0.3,2.1,0,2.8,0.7c6.8,6.2,16.7,10.4,27.1,11.3c1.3,0.1,2.3,1,2.7,2.3c0.5,1.8,0.9,3.6,1.4,5.4c0.5,1.9,2.6,2.9,4.4,2
c7.3-3.8,15.1-6.9,23.1-9.4c1.8-0.6,2.7-2.7,1.8-4.3c-1.6-3-0.3-7.6,2.9-10.1c4.2-3.5,10.1-5.7,13.2-9.7c0.6-0.8,1.4-1.2,2.4-1.3
c0.8,0,1.6,0,2.4-0.1c1.2,0,2.2-0.7,2.7-1.8c0.5-1.2,1.1-2.3,1.6-3.5c0.3-0.6,0.8-1.1,1.4-1.4c11.4-5.8,24-10.1,37.2-12.6
c14.5-12.2,35.4-19.5,56.5-19.7c2.7-0.1,5.4,0.1,8-0.4c2.7-0.5,5.2-1.5,7.8-2.3c2.7-0.8,4.7-3.1,6.8-4.6c0.6-0.4,1.2-0.8,2-0.7
c1.7,0.2,1.8,2.5,3.4,2.8c1.8,0.2,3-2.4,4.7-1.8c0.6,0.2,1,0.9,1.6,1.1c1.6,0.6,3-1.8,4.8-1.5c0.6,0.1,1.1,0.6,1.6,0.9
c0.7,0.4,1.6,0.5,2.5,0.6c2.9,0.4,5.9,0.8,8.8,1.2c2.9,0.4,6,0.8,8.6,2c2.1,1,3.8,2.5,4.5,4.3c0.3,0.8,1,1.3,1.8,1.2
c4.4-0.6,8.9-0.2,13.1,1.1c-0.3,0.1-0.5,0.3-0.3,0.6c0.4,0.4,1.3,0.3,1.9,0.1c0.6-0.1,1.6,0,1.7,0.5c-0.8,0.3-1.6,0.8-2.1,1.4
c2.6,0.3,5.3,0.4,7.9,0.4c1.1,0,1.9,1.1,1.6,2.2c-0.2,0.7-0.3,1.4-0.3,2.1c0,1.4,1.8,2.2,2.8,1.2c0,0,0,0,0,0
c0.7-0.7,1.9-0.6,2.5,0.2c1.2,1.6,2.4,3.2,3.6,4.9c0.7,1.1,2.3,1,2.9-0.2c0-0.1,0.1-0.1,0.1-0.2c0.5-1,2-1.2,2.7-0.3
c0.9,1,1.9,2.1,2.8,3.1c0.7,0.7,1.8,0.7,2.5-0.1c2.4-2.6,8-3.4,10.7-6.1c2-2,2.5-5.1,5.4-6.1c0.3-0.1,0.7-0.2,1.1-0.1
c0.4,0.1,0.7,0.4,0.9,0.8c1.3,1.9,2.5,3.7,3.8,5.6c0.5,0.7,1,1.5,1.9,1.7c1.5,0.3,2.8-0.8,3.7-1.8c1.6-1.9,3.1-3.7,4.7-5.6
c0.5-0.6,1-1.2,1.9-1.5c2.4-0.7,3.8,1.9,5.9,3c0.8,0.4,1.8,0.1,2.2-0.7c0.5-0.9,0.6-2,1-3c0.4-1.4,1.6-3.1,3.4-3
c0.7,0,1.3,0.3,2,0.2c0.4-0.1,0.8-0.4,0.8-0.8c0-0.4,0-0.8,0.3-1.1c2.8-3.3,5.7-5.8,8.6-9.2c1.9-2.4,5.2-5,8.3-3.8
c1.9,0.8,2.7,2.9,4.7,3.6c3.3,1.2,6.2-2.1,8.9-4c3.2-2.3,9.3-2.2,11.4,0.5c0.5,0.7,1.4,0.7,2.2,0.3c4.1-2.4,9.3-3.2,14.4-3.8
c4.6-0.5,9.3-0.9,13.8,0.1c3.8,0.9,7.4,3.1,8.7,6.1c0.4,0.8,1.2,1.2,2.1,0.9c5.1-1.7,10.2-3.5,15.4-5.3c1.4-0.5,3-1,4.6-0.9
c3.8,0.4,5.7,4.3,8.9,5.6c0.9,0.3,1.8-0.1,2.2-1c0.9-2.4,5.1-3.8,8.6-3.7c4.1,0.1,8.4,1.3,12.2,0.1c5.5-1.7,7-7,7.3-11.8
c0.1-1,1-1.7,2-1.5c3.1,0.7,6,2,8.3,3.8c1.1,0.8,2.6,0.1,2.7-1.3c0.1-1.3,0-2.6-0.3-3.8c-0.2-0.9,0.4-1.9,1.4-2
c2.1-0.2,4.2-1.8,4.9-3.6c1-2.3,0.7-4.9,1.4-7.2c1.3-4,5.9-7.2,11-7.7c0.8-0.1,1.5,0.4,1.8,1.1c1.7,5.3,2.6,10.8,2.5,16.3
c0,0.9,0.7,1.7,1.6,1.7c7.3,0.4,14.7,0.5,22,0.2c0.7,0,1.3,0.3,1.5,0.9c1.9,3.8,5,7.8,9.8,7.4c2.1-0.2,3.9-1.2,6-1.6
c6.2-1.1,11.2,3.8,14,8.3c0.8,1.3,1.9,2.8,3.6,3c1.1,0.2,2.3-0.2,3.4-0.6c3.8-1.3,7.5-2.7,11.3-4c4.6-1.6,8.1-3.6,12.1-5.8
c0.5-0.3,1.2-0.3,1.7,0c2.5,1.6,5.9,2.2,9,2.6c15.2,2.1,30.5,3.6,45.8,4.5c2.1,0.1,4.4,0.1,6.1-0.9c2.1-1.3,2-3.6,1.3-5.8
c-0.4-1.1,0.4-2.2,1.6-2.2c7.5-0.2,15.1-0.2,22.6-0.1c2.1,0,4.4,0.1,6.2,1c1.8,0.9,2.9,2.5,4.3,3.9c3.9,3.9,10.8,5.5,16.7,4.2
c1-0.2,2.1,0.1,2.8,0.8c7.5,7.1,14.1,14.7,19.8,22.8c1.3,1.9,4.1,1.9,5.3-0.1c0.7-1.2,1.6-2.2,2.9-3c2.9-2,8.2-1.9,9.7,0.9
c0.8,1.5,0.4,3.2,1.3,4.6c2.4,3.4,8.8,1.2,13.8,1.4c7.6,0.3,13,7.4,19.5,9.4c2.6,0.8,4.9-1.9,3.8-4.3c-0.4-0.9-0.9-1.7-1.3-2.6
c-1.1-1.9,0.1-4.3,2.2-4.7c3.6-0.6,7-2.2,9.2-4.6c2.2-2.5,3.1-5.8,2.4-8.9c-0.5-2.5,1.8-4.6,4.2-3.7c3.1,1.2,6,3.8,8.6,5.8
c4.9,3.7,11.9,5.4,18.5,4.6c4.7-0.6,9-2.3,13.6-3.2c12-2.2,24.4,1.9,36.6,1.2c13-0.7,27.8-6.3,37.9,0.2c-1.5-0.8-0.4-3,1.5-3.3
c1.1-0.2,2.1,0,3.2,0.4c0.9,0.3,1.8-0.3,1.9-1.2c0.6-3.3,4.2-6.3,8.3-6.6c0.7-0.1,1.3,0.4,1.5,1.1c0.5,2,1.7,4,3.2,5.7
c0.5,0.5,1.2,0.6,1.8,0.3c2.2-1.2,4.9-1.8,7.5-1.8c0.9,0,1.5,0.7,1.5,1.6c-0.1,2.1,0.2,4.2,1.1,6.1c0.4,0.9,1.6,1.1,2.3,0.5
c3.5-3.1,8.1-5.6,13.3-5.8c4.9-0.2,9.9,2.6,11,6.2c0.3,0.9,1.4,1.3,2.2,0.8c2.8-1.9,6.6-2.8,10.2-2.5c0.8,0.1,1.5-0.5,1.6-1.2
c0.3-1.8,0.5-3.5,0.8-5.3c0.1-0.9,1.1-1.5,2-1.2c4.7,1.7,10.7-1.1,15.1-3.7c5-2.9,12.6-5.6,16.7-1.8c2.9,2.6,7.4,6.2,11.6,5.2
c4.2-1.1,7.5-3.5,11.5-4.9c5.4-1.8,11.4-1.6,17.2-1.4c9.6,0.4,19.7,0.9,27.7,5.1c3.6,1.9,7.4,4.6,11.6,3.9c2.7-0.5,4.8-2.3,7.4-2.8
c3.3-0.7,6.6,0.8,9.7,1.9s7.4,1.5,9.4-0.7c0.9-0.9,1.3-2.2,2.4-2.8c2.6-1.6,6.3,0.8,9.5,0.2c2.8-0.6,3.9-3.2,5.8-5
c4.6-4.2,12.7-2.3,19.2,0.3c0.9,0.4,2-0.3,2-1.3c0,0,0,0,0,0c0.1-1.1,1.2-1.7,2.1-1.2c4.9,2.7,12.6,1.2,17.9-1.7
c5.8-3.2,10.5-7.7,17-9.7c0.5-0.2,1.1,0,1.5,0.4c2.7,2.8,5.9,5.3,9.6,7.2c1,0.5,2.1-0.2,2.2-1.3c0.1-3.4,0.4-6.9,1-10.4
c0.1-0.7,0.8-1.3,1.6-1.2c7.5,0.5,14.9,0.9,22.3,1.3c4.7,0.3,10.4,0.1,13.1-2.7c0.5-0.6,1.4-0.7,2-0.2c2.1,1.7,1.7,5.8,4.8,6.4
c1.3,0.3,2.7-0.3,3.9-0.9c2.8-1.3,5.7-2.6,8.6-4c0.7-0.3,1.5-0.1,1.8,0.5c2.4,3.9,6,7.4,10.2,10c2,1.3,4.7,2.5,7.1,1.8
c3.1-0.8,4.1-3.8,6.2-5.8c2.8-2.5,7.6-3.1,11.7-2.2c4,0.9,7.6,2.9,11,4.8c7.2,4.2,14.4,8.3,21.6,12.4c3.8,2.2,8.7,4.5,13,3
c9.1-3.2,21.1,0.9,24.4,8.3c0.3,0.8,1.2,1.2,2,0.8c2.5-1.2,5-2.4,7.4-3.5c0.6-0.3,1.3-0.1,1.8,0.4c1.2,1.4,2.1,2.9,2.8,4.4
c0.4,0.8,1.4,1.2,2.2,0.7c2.8-1.8,6-3.5,9.6-3.1c2.2,0.3,4.1,1.3,6.1,2.1c5,1.9,11.1,1.7,16-0.4c2.7-1.1,4.9-4.1,3.9-6
c-0.4-0.8,0.2-1.8,1.1-2c5.1-1.2,11-0.2,14.9,2.7c2.2,1.6,3.8,3.6,6.1,5.1c2.3,1.4,5.8,2.2,8.1,0.7c2.5-1.5,2.6-4.8,5.2-6.2
c3.8-2,8.2,1.5,12.6,2.4c4,0.8,8.2-0.8,11.1-3.2c2.4-2.1,4-4.7,5.4-7.3c0.4-0.7,1.2-0.9,1.9-0.6c3.8,1.6,7.6,3.3,11.4,5
c0-1.6,0.1-3.2,0.2-4.8c3.2-0.1,6.4-0.2,9.7-0.3c1.1,0,2-0.7,2.3-1.8c0,0,0,0,0,0c0.4-1.2,1.6-1.9,2.9-1.7c2.2,0.4,4.4,0.6,6.3-0.2
c1.9-0.7,2.8-2.9,2.1-4.3c-0.6-1.2-0.2-2.7,1-3.3c1.8-1,4-1.3,6.3-0.9c2.6,0.4,4.8,1.7,6.1,3.4c0.8,1.1,2.2,1.3,3.4,0.7
c2-1.2,4.3-2.1,6.7-2.7c1.2-0.3,2.7-0.5,3.7,0.2c2.4,1.7-1.8,4.3-2.6,6.8c-0.8,2.3,2,4.7,5,5.1c1.9,0.2,3.7,0,5.6-0.5
c1.5-0.4,2.9,0.6,3.2,2c0.3,1.6,1.1,3,2.6,4.1c2.3,1.8,6.5,2.1,8.8,0.2c3.8-3.1,1.3-10,6.4-11.5c3.8-1.1,6.7,2.3,10.1,4.3
c1.5,0.9,3.5,0,3.8-1.7c0.2-1.2,0.3-2.5,0.3-3.7c0-1.6,1.5-2.8,3.1-2.5c5.3,1.1,11.3,0.2,15.6-2.7c2.2-1.5,4.5-3.7,7.3-3.4
c3.7,0.3,6.1,4.5,9.3,5c1.1,0.2,2.2-0.5,2.6-1.6c0.1-0.2,0.2-0.5,0.3-0.7c0.4-1.3,1.7-2,3-1.7c2.6,0.6,5.3,0.6,6.7-1
c0.6-0.7,0.8-1.6,1.3-2.4c2-2.9,7.6-2.1,11.3-0.5s8.1,3.6,11.7,1.9c1.2-0.6,2.1-1.5,3.1-2.3c4.6-3.8,11.6-5.1,18.2-6.1
c6.5-1,12.9-2.1,19.4-3.1c3.6-0.6,7.3-1.1,10.8-0.2c3.3,0.9,6.1,3.1,9.6,3.5c5.8,0.6,10.1-3.7,14.5-6.8c6.5-4.5,15-6.5,23.2-8.5
c1.8-0.4,3.7-0.9,5.6-0.6c2.2,0.3,4,1.5,6,2.4c2.7,1.2,5.9,1.9,9,1.8c1.6,0,2.8-1.6,2.4-3.2c-0.5-1.9-1.9-4-1.1-5.9
c0.8-1.9,3.5-2.7,5.6-3.8c3.7-2.1,5.6-6,5-9.5c-0.2-1.4,0.7-2.7,2.1-2.9c2.8-0.4,5.5-0.9,8.3-1.2c4.6-0.7,10.8,6.6,15,5
c1.9-0.6,2.6-2.4,3.3-4c1.5-3.4,3.7-6.7,7.2-8.9c3.6-2.2,8.5-3.1,12.6-1.7c2.8,1,4.9,3,7.8,3.8c2.1,0.6,4.9-0.1,6-1.4
c0.6-0.7,1.5-1,2.4-0.9c2.3,0.4,5.2-0.7,6.3-2.4c0.7-1,2-1.4,3.2-0.9c1.4,0.6,2.8,1.3,4.3,1.9c1.1,0.5,2.3,1.1,3.6,0.9
c0.5,0,1-0.3,1.4-0.6c0.6-0.5,1.4-0.8,2.1-0.5c2.9,0.9,7.1-0.9,10.7-1.6c3.1-0.6,6.4-0.1,9.6,0.4c4.4,0.7,8.8,1.4,13.2,2.1
c1.3,0.2,2.7,0.4,3.7,1.2c1.9,1.5,1.6,4.2,3.8,5.3c1.8,1,4.3,0.2,6.4-0.5c7.7-2.4,16.7-2.3,24.3,0.4c1.4,0.5,2,2.1,1.4,3.5
c-0.8,1.7-1.4,3.4-1.9,5.2c-0.5,1.9,1.1,3.5,3,3.2c4-0.8,8.3-0.7,12.2,0.2c1.9,0.5,4,1.1,5.7,0.4c2.8-1.2,2.3-4.6,4.2-6.6
c2.9-3.2,9-1.2,13.8-0.2c7.5,1.5,15.2-0.5,22.2-3.1c1-0.4,2.2-0.1,2.9,0.8c1.5,1.8,3,3.5,5.2,4.8c2.7,1.6,6.5,2.2,9.4,0.9
c2.6-1.2,3.9-3.6,6.1-5.3c1.4-1,3.8-1.5,5.5-1.1c1,0.3,2.1-0.3,2.7-1.1c2.1-3,8.1-4.5,12.6-3c5.6,1.8,9,6.2,12.7,10
c3.2,3.3,7.6,6.6,12.7,7c1.1,0.1,1.9,0.9,2.2,1.9c0.8,2.6,4.3,4.6,7.5,3.9c2.4-0.5,4.2-2,6.2-3.1c3.8-2.1,8.9-2.9,13.4-2.1
c0.4,0.1,0.7,0.1,1.1,0c3.3-0.9,6.1-2.7,8.5-4.7c2.4-2.2,6.2-4,9.5-2.9c1.6,0.6,3.1,2,4.9,1.7c2.3-0.3,2.4-2.6,2.9-4.6
c0.4-1.6,2.2-2.4,3.7-1.6c3.4,1.8,6.4,3.9,9,6.4c1.5,1.5,4.1,0.5,4.3-1.6c0.2-2.4,0.5-4.7,0.7-7.1c0.2-1.7,0.5-3.6,2.2-4.7
c2.4-1.5,6-0.3,8.7-1.4c4.1-1.6,3.2-6.5,5.4-9.7c1.4-2,4.2-3.1,7.1-3.1c2.1,0,3.3,2.4,2,4.1c-0.9,1.2-1.5,2.4-1.8,3.7
c-0.5,2.5,1,5.5,4.1,6.2c1.2,0.2,2.4,0.1,3.6,0.5c5.5,1.5,1.8,8.8,5.2,12.7c2.1,2.5,6.7,2.7,10.4,2.1s7.5-1.7,11.2-1.1
c4.1,0.7,7.2,3.5,11.2,4.3c5.8,1.2,12.7-1.8,18-0.5c1.6,0.4,2.8,1.9,2.7,3.6c-0.2,3.5-0.1,7.1,1.9,10.2c2.4,3.8,8.4,6.4,13.1,4.4
c2.5-1,4.2-3.2,6.9-4c3.2-0.9,6.9,1.2,7.7,3.7c0.2,0.7,1.1,0.8,1.6,0.3c0.8-0.8,1.5-1.6,2.2-2.4c0.3-0.3,0.7-0.4,1.1-0.2
c1.6,0.8,4,0.5,5.3-0.6c0.4-0.4,1-0.3,1.4,0.2c2.4,3.4,7.1,5.8,12.1,6c0.1,0,0.3,0,0.4,0.1c5.2,2.7,14.4-1.4,18.5,2.3
c2.1,1.9,2.5,5.4,5.5,5.6c2.9,0.3,4.6-2.9,7.5-3.4c1.1-0.2,2.3,0,3.4,0.2c18.5,3.7,37,7.4,55.5,11.1c5.2,1,10.7,2.2,14.1,5.5
c2.8,2.7,3.6,6.3,5.1,9.6s4.3,6.7,8.6,7.3c5.2,0.6,10.3-3,15.3-1.7c1.7,0.5,3.2,1.5,4.5,2.6c5.9,4.6,11.7,9.3,17.4,14.1
c0.6,0.5,1.4,0.1,1.5-0.6c0.5-3.5,0.9-7,1.4-10.4c0.1-0.4,0.4-0.8,0.9-0.8c0.6-0.1,1.3,0,1.9,0.1c0.7,0.1,1.3-0.5,1.1-1.2
c0,0,0,0,0,0c-0.2-0.8,0.6-1.5,1.3-1.1c1.8,0.9,3.8,1.5,5.8,1.8c0.5,0.1,1-0.3,1.1-0.9c0.3-4.1,0.4-8.2,0.7-12.3
c0-0.4,0.3-0.8,0.7-0.9c16.2-3.6,31.6-9.3,45.7-16.6c5.7-3,13.4-6.3,18.9-3c4.1,2.5,6.4,8.4,11.4,7.4c2.4-0.4,3.8-2.4,5.7-3.7
c3.7-2.4,8.9-2.1,13.6-1.8c13.8,0.7,27.7-0.8,40.8-4.3c0.5-0.1,0.8-0.7,0.6-1.3c-0.7-1.9,1-4.2,3.4-5c2.7-0.7,5.6-0.1,8.1,1
c0.3,0.1,0.5,0.4,0.5,0.8c0.2,2.6,6,4.9,9.4,4.7c3.6-0.1,7.4-1.2,10.6,0.1c4.1,1.7,4.7,6.1,6.3,9.5c1.7,3.4,7.6,6.5,10.4,3.5
c1.1-1.2,1.1-3.1,2.6-4.1c3.9-2.5,8,4.4,12.9,3.9c4-0.4,4.7-5.2,8.4-6.7c3.2-1.4,7.1,0.5,9.2,2.9c2.1,2.3,3.5,5.2,6.2,7
c2.5,1.6,6.9,1.8,8.6-0.1c0.3-0.4,0.9-0.4,1.3-0.1c1.2,1,2.6,1.8,4.3,2.3c0.4,0.1,0.9-0.1,1.1-0.5c0.5-1.1,1.1-2.3,1.6-3.4
c0.3-0.7,1.3-0.7,1.7,0c2.9,5.1,11,7.5,18.3,8.9c0.6,0.1,1.2-0.4,1.1-1c-0.4-2.8,3.6-5.7,7.5-6.2c4.5-0.6,9.1,0.4,13.6-0.2
c8.8-1.3,16.4-9,24.8-6.5c2.1,0.7,3.9,1.9,5.9,2.8c6.1,2.7,14.4,1.5,19-2.7c0.4-0.4,1.1-0.3,1.4,0.1c2.1,3,3.5,6.3,4.1,9.8
c0.1,0.7,0.9,1,1.4,0.6c5.1-3.4,11.9-5.3,18.6-5c4.4,0.2,10,1,12.7-1.6c0.3-0.3,0.8-0.3,1.1-0.1c1.7,1,3.3,2.1,5,3.2
c0.4,0.2,0.9,0.2,1.2-0.2c0.9-1,1.9-2,2.9-3c0.3-0.3,0.9-0.4,1.3-0.1c3.1,2.5,6.2,5.1,9.3,7.6c0.5,0.4,1.3,0.2,1.5-0.4
c0.6-2,0.8-4.1,0.5-6.2c-0.1-0.7,0.5-1.2,1.1-1.1c11,2.3,17.6,11.7,22.6,20c0.6,0.9,1.3,2,2.6,2.3c1.7,0.4,3.3-0.7,4.6-1.5
c10.7-7.1,25.7-7.9,39.5-8.3c4.8-0.2,10.4,0,13.5,2.7c0.4,0.3,0.9,0.4,1.3,0.3c1.3-0.4,2.8-0.2,4,0.3c0.8,0.4,1.8,0,2.1-0.8
c0.2-0.5,0.3-1,0.5-1.5c0.3-0.8,1.1-1.2,1.9-0.9c3,1.2,6.8,1,9.7-0.4c0.5-0.2,1-0.2,1.5,0.1c2.1,1.4,4.2,2.9,6.3,4.3
c0.7,0.5,1.6,1.1,2.6,0.9c1.6-0.2,1.8-1.9,2.6-3c2.3-3.2,8.2-1.6,12.3,0.1c3.8,1.5,10.2,2,11.4-1.2c0.1-0.3,0.4-0.6,0.7-0.7
c3.7-1.9,7.6-3,11.3-4.9c0.5-0.2,1.1-0.2,1.5,0.1c2.5,1.9,5.3,3.5,8.2,4.9c0.6,0.3,1.4,0.6,2.2,0.5c2-0.2,2.1-2.5,2.9-4.1
c1.4-2.9,6.2-3.5,10.1-3.7c5.8-0.2,11.6-0.5,17.3-0.7c2.3-0.1,4.9-0.3,6.4-1.7c1.2-1.2,1.4-2.8,1.4-4.3c0-3-0.5-6.1-1.4-9.1
c-0.4-1.2,0.8-2.2,1.9-1.8c2.4,0.9,5,1.6,7.6,1.6c3.2-0.1,6.5-1.6,7.6-3.8c0.3-0.7,1.1-1.1,1.9-0.8c0.4,0.1,0.8,0.3,1.2,0.4
c0.8,0.3,1.8-0.2,2-1c0-0.1,0-0.2,0.1-0.3c0.1-0.8,0.9-1.3,1.7-1.1c1.5,0.2,2.9,0.5,4.4,0.7c0.8,0.1,1.6-0.4,1.7-1.3
c0.3-1.8,0.4-3.5,0.4-5.3c0-1,1-1.7,2-1.4c1.6,0.6,3.2,1.1,4.9,1.4c0.8,0.2,1.6-0.4,1.7-1.2c0.3-1.5,0.6-3.1,0.8-4.6
c0.1-0.8,0.8-1.3,1.6-1.2c8,0.8,16.2,0.7,24.2-0.4c1.1-0.2,2,1,1.5,2.1c-1.8,4.1-3.9,8-6.2,11.9c-0.7,1.2,0.6,2.7,1.9,2.1
c4.5-2,9-4.1,13.5-6.1c4.8-2.1,10.2-5.2,11.6-9.2c0.3-1,1.5-1.4,2.3-0.7c1.3,1.1,2.7,2.1,4.4,2.8c2.4,0.9,5.6,0.8,7.4-0.7
c0.4-0.4,1-0.5,1.6-0.3c4,1.8,8.9,3.3,12.7,1.5c1.6-0.8,2.7-2,4.1-3c2.9-2,6.9-2.8,10.6-2c0.3,0.1,0.5,0,0.8-0.1
c6.4-2.2,15.7,1.9,19.9,6.3c1.3,1.5,3.1,3.4,5.3,2.8c2.5-0.7,2-3.9,4-5.1c3.3-2,8.1,2.6,11.6,1c2.8-1.2,1.9-5.1,4.4-6.6
c4.1-2.5,11.1,3.6,14.5,0.5c0.8-0.8,1-1.9,2-2.5c1.9-1.3,4.6,0.4,6.8,1.3c3.9,1.7,9.2,0.7,11.9-2c0.6-0.6,1.5-0.5,2.1,0
c2.5,2.4,7.3,3.1,11.4,2.4c4.7-0.8,8.9-2.9,13.6-3.6c3.2-0.5,6.5-0.3,9.6-0.9c2.4-0.5,4.8-1.5,6.1-3.1c0.6-0.7,1.6-0.7,2.2-0.1
c0.8,0.9,1.7,1.8,2.5,2.8c0.5,0.5,1.3,0.7,1.9,0.3c0.2-0.1,0.3-0.2,0.5-0.4c0.6-0.5,1.4-0.5,2,0c1.9,1.6,5.7,1.7,7.7,0.1
c0.6-0.5,1.4-0.5,1.9,0c1.4,1.2,2.7,2.4,4.1,3.6c0.4,0.4,1.1,0.5,1.6,0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.8-0.5,1.8-0.2,2.2,0.6
c1.2,2.7,2.5,5.2,4.6,7.4c2.7,2.7,6.9,4.7,11.2,4.2c5.8-0.7,9.3-5.2,12.7-9s9-7.5,14.4-5.9c2.1,0.7,3.7,2.1,5.2,3.5
c3.9,3.9,7.3,8.1,10.1,12.5c0.5,0.9,1.8,0.9,2.4,0.1c3.1-3.9,6.1-7.8,9.2-11.7c0.7-1,2.4-1.9,3.6-1.8c0.7,0.1,1.4-0.2,1.6-0.9
c0-0.1,0.1-0.2,0.1-0.3c0.4-0.9,1.6-1.2,2.4-0.5c1.5,1.3,3.6,2.3,5.7,2.7c0.8,0.2,1.7-0.4,1.8-1.2c0.2-1.9,3.6-3.8,6.5-3.6
c3.7,0.3,7.3,2.1,10.9,1.5c2.5-0.4,4.7-2.1,7.3-2.1c3.7,0,6,3,7.9,5.6c1.9,2.5,5,5.3,8.5,4.4c4.3-1.1,9.8,1.5,12.5,4.3
c1,0.9,2.2,2.2,3.7,1.9c2-0.5,1.5-2.9,2.9-4.2s4-0.7,5.9,0.2c1.8,0.8,4.1,1.8,6,1c1.3-0.5,1.9-1.7,3.2-2.3c3.5-1.6,7.1,2.7,11.1,2.4
c1.8-0.1,3.5-1.2,5.2-0.9c1.4,0.3,2.4,1.5,3.8,1.8c2.6,0.6,4.6-1.8,7.1-2.7c1.4-0.5,3-0.4,4.4,0.1c0.9,0.3,1.9-0.4,1.9-1.4L3990.3,1
H0L0.1,197.8z"
      ></path>
      <path
        class="elementor-shape-fill ha-shape-divider"
        d="M0.1,131.7c28.7-4.6,58.2-5.7,87.4-3.5c1.5,0.1,2.5,1.3,2.7,2.8c0.6,5.5,13.3,8.3,20.5,4.6c8.2-4.2,12.8-12,21.6-15.2
c17.4-6.4,40.2,8.1,56.5,2.4c1.8-0.6,3.8,0.4,4.1,2.3c2.5,14.9,24.6,25,44.8,26.1c22,1.2,44-4.2,66-3.1c15.7,0.8,31.1,5,44,12.1
c3.1,1.7,6.1,3.6,9.9,4c5.3,0.7,10.3-1.6,15.2-3.5c15.4-5.7,33.8-6.6,50-2.3c22,5.8,44.6,20.7,65.3,12.5
c11.8-4.8,18.4-16.1,31.3-18.5c6.1-1.1,12.5,0.1,18.6,1.2c37.2,7.3,74.5,14.4,111.7,21.6c0.4,0.1,0.7,0,1.1-0.2
c8.7-4.4,20.4-4.8,29.7-1.2c0.7,0.3,1.4,0.1,1.9-0.4c12.8-14.2,31.1-25.5,51.8-31.8c0.5-0.2,1.1-0.1,1.5,0.3
c12.2,8.8,31.9,10.5,46.2,3.9c4-1.8,7.6-4.3,11.9-5.6c4.7-1.5,9.8-1.6,14.7-2c24.4-2.2,47.1-13.8,59.6-30.2c0.6-0.8,1.8-0.8,2.6-0.1
c5.7,6,15.3,9.9,24.9,9.4c9.8-0.5,19.1-5.7,22.3-12.9c0.4-0.8,1.2-1.2,2-0.9c18.6,5.8,39.5,7.2,59,3.6c0.3-0.1,0.6,0,0.8,0.1
c9.9,3.4,22.4,2.1,30.7-3.4c4.3-2.8,13-1.7,18.6-2.8c0.6-0.1,1.2,0.1,1.6,0.5c2.7,3,6.7,5.3,11.3,6.3c0.3,0.1,0.7,0,1-0.1
c6.1-2.6,12.9-4,19.7-4.4c23.7-1.2,49.3,10.9,70.3,3c1.2-0.5,2.5-0.1,3.4,0.8c8.5,8.8,25.7,9.7,40.3,9.4c4.7-0.1,9.3-0.1,14-0.2
c11.6-0.1,25.4-1.6,31.6-8.4c1.2-1.4,3.4-1.4,4.6,0c13.5,14.6,47.3,15.7,57,32c0.7,1.2,2.3,1.8,3.7,1.3c2.8-0.9,5.6-1.8,8.4-2.7
c0.5-0.1,0.9-0.1,1.3,0.2c2.9,2.1,5.9,4.3,8.8,6.5c0.6,0.4,1.3,0.4,1.9-0.1c5.3-4.7,11.6-9.4,19.5-9.6c3.2-0.1,6.5,0.7,9.6,0
c2.4-0.6,4.4-2,6.2-3.4c8.6-6.4,17.1-12.9,25.7-19.2c0.6-0.4,1.4-0.4,1.9,0.2c9,9.4,22.9,15.9,37.9,17.7c14.8,1.9,29.9-0.7,43.8-4.9
c0.4-0.1,0.7-0.4,0.9-0.8c3.9-7.8,9.1-14.6,13-22.4c0.2-0.4,0.6-0.7,1.1-0.8c17.5-2.5,35.1-5,52.6-7.5c3.8-0.6,7.6-1.1,10.9-2.6
c3.2-1.4,5.7-3.6,8.9-5.1c12.7-6,28.4,1.5,40.1,8.6c11.7,7,27.9,14.2,40.2,7.7c4.5-2.4,8.1-6.4,13.5-6.5c2.4,0,4.7,0.9,6.9,1.7
c11.7,4.9,22.5,11.1,31.7,18.5c2.9,2.3,5.8,4.8,9.5,6.3c4.3,1.7,9.2,1.9,13.9,2.7c8.6,1.5,16.2,5.2,24.7,6.9
c8.1,1.7,17.8,1.1,23.2-3.8c0.5-0.4,1.2-0.5,1.8-0.1c8.8,6.2,23.4,3.7,35,0.7c8.8-2.3,17.6-4.6,26.3-6.9c3.3-0.8,6.9-1.7,10.1-0.7
c6,1.9,8.9,9.6,15.1,8.1c3.6-0.9,4.6-4.4,6.3-7c1.6-2.4,6.8-4.1,8.4-1.9c0.2,0.3,0.5,0.5,0.9,0.6c15,2.5,31.9,7.1,43.5,14.8
c0.5,0.4,1.3,0.3,1.8-0.1c2.6-2.2,5.1-4.3,7.7-6.5c0.4-0.4,1.1-0.5,1.6-0.2c20.3,10.2,48.1,10.7,69,1.2c6.2-2.8,14.6-6.3,20.1-3.8
c1.3,0.6,2.8,0,3.4-1.3c4.1-9.3,8.1-18.6,12.3-27.8c0.9-2,1.9-4.2,4.1-5.6c7.7-4.7,18.5,5.3,27.6,4.5c1.5-0.1,2.5-1.7,2.1-3.2
c-2.2-9,8.1-19,20.1-18.7c10.2,0.3,18.2,6.4,24.9,12.6c0.9,0.9,2.4,0.9,3.3,0.1c10-8.5,24.7-13.7,39.6-13.8c0.9,0,1.7,0.4,2.1,1.1
c3.5,5.3,7,10.7,10.5,16c1,1.5,3.1,1.5,4.2,0.1c4-5.5,8.1-11,12.1-16.5c0.7-0.9,1.9-1.3,3-0.9c2.4,0.9,4.8,1.8,7.2,2.7
c0.6,0.2,1.3,0.2,2-0.1c11.9-5.1,28.8,0.4,37.9,8.8c1,0.9,2.5,0.8,3.4-0.1c3-2.8,9.7-3.2,14.9-3.4c5-0.2,11.2-2.5,12.5-5.6
c0.5-1.2,1.9-1.7,3.1-1.3c10.5,3.3,24.8-3,26.5-11.7c0.3-1.5,1.8-2.4,3.3-2c5.3,1.7,11.2,2.2,16.8,1.2c0.8-0.1,1.7,0.1,2.3,0.7
c5.6,5.9,16.2,8.7,25.3,6.6c0.8-0.2,1.5-0.7,1.8-1.4c1.9-4.8,11.5-5.4,18.4-4.3c18.4,3.1,36.1,8.2,52.6,15.2
c1.4,0.6,3.1-0.2,3.5-1.7c1.6-6.4,10.4-11.6,19.1-11.4c8.7,0.2,16.5,4.3,21.6,10c1.1,1.3,3.1,1.1,4-0.3c2.1-3.4,4.7-6.6,7.6-9.6
c0.9-1,2.4-1.1,3.4-0.2c14.4,11.8,36.2,18,57,15.9c4.2-0.4,8.6-1,12.5-0.2c0.9,0.2,1.8-0.1,2.4-0.8c9.9-10.6,19.7-21.3,29.5-31.9
c2.4-2.6,5.1-5.4,9-6.6c6.7-2,14,1.6,19.2,5.5c5.1,3.9,10.2,8.6,17.3,9.5c5.8,0.7,12.3-3.3,12.2-7.4c0-1.5,1-2.7,2.5-2.7
c4.8-0.1,9.6-0.3,14.4-0.4c14.3-0.4,29.9,7.5,33.2,18.1c0.4,1.2,1.5,1.9,2.7,1.8c5.4-0.5,10.6-2.2,14.8-4.9c1.3-0.8,3-0.7,4.2,0.4
c3.5,3.2,6.5,6.7,8.8,10.5c1,1.5,3,2,4.6,1.2c2.6-1.4,5.2-2.8,7.8-4.2c0.5-0.3,1.2,0,1.3,0.6c1,3.5,2.3,7,5.8,9.1
c2.9,1.8,7.7,1.7,10.3,0c0.5-0.3,1.2-0.1,1.4,0.4c0.6,1.8,2.8,3.4,5.2,3.4c16.5,3.5,32.9,7,49.3,10.5c11,2.3,22.3,4.8,31.3,10.3
c4.8,2.9,8.9,6.5,14.4,8.5c5,1.7,11.8,1.5,15.2-1.6c0.5-0.4,1.2-0.3,1.5,0.3c4.1,8.8,20.5,13,30.5,7.3c0.4-0.2,0.9-0.1,1.2,0.2
c3.9,4.9,11.6,7.6,18.9,6.6c7.1-0.9,13.2-5.5,14.8-10.9c0.2-0.5,0.7-0.8,1.2-0.6c10,4.1,18.1,10.7,23,18.5c0.1,0.2,0.4,0.4,0.6,0.4
c2,0.3,4.1-0.3,5.6-1.3c0.4-0.3,1-0.3,1.3,0.1c3.9,4.9,10.6,10.2,17.1,7.6c5.9-2.3,5.4-8.9,7.3-13.9c5.6-14.6,35.5-17.5,41-31.5
c0.3-0.7,1.3-0.8,1.7-0.1c3,5,7.9,9.2,13.9,12c0.4,0.2,1,0,1.2-0.4c1.9-3.5,3.8-7.1,5.7-10.6c0.2-0.3,0.5-0.5,0.8-0.5
c4.1-0.1,7.4-2.7,10.4-5c15.8-12.4,36.2-21.4,58.1-25.5c0.2,0,0.4,0,0.6,0.1c8.7,4.5,14.8,11.8,16.2,19.9c0.1,0.7,0.8,1,1.4,0.6
c4.8-3.1,11.5-4.2,17.5-3c0.4,0.1,0.8-0.1,1-0.4c2.7-4.6,8.2-8.3,14.5-9.6c2.3-0.7,4.5,1,5.8,2.7c1.2,1.6,2.8,3.6,5.2,3.5
c0.2,0,0.4,0,0.5-0.1c2.9,11.8,21.6,22.5,37,21.3c0.2,0,0.4,0,0.6,0.2c2.6,2,5.2,4,7.8,6c0.3,0.3,0.8,0.3,1.1,0
c2.8-2,5.1-4.5,6.7-7.3c0.3-0.6,1.1-0.6,1.5-0.1c1.9,2.3,4.3,4.4,7.2,5.9c0.2,0.1,0.4,0.1,0.7,0.1c7.3-1.7,10.4-10.6,18.1-10.6
c7.6,0,14.9,8.6,20.3,5.6c0.5-0.3,1.1-0.1,1.4,0.4c3.2,7.6,4.5,15.8,3.7,23.8c-0.1,0.6,0.5,1.1,1.1,1c15.3-2.4,32.2,4,39.2,14.8
c1.1,1.7,2,3.5,4,4.5c5.9,2.8,10.9-4,11.9-9.6c0.1-0.6,0.6-0.9,1.2-0.7c8.3,2.7,19-1.2,22.3-7.6c0.6-1.1,1.9-1.5,3.1-1.1
c5.7,2.3,14.4-3.1,16.5-8.7c2.4-6.4,2.6-14.2,9.7-17.7c0.2-0.1,0.5-0.2,0.7-0.2c17.5,0.7,34.6,6,48.1,14.7c0.4,0.3,0.9,0.3,1.4,0.1
c7.9-3.4,18.1-3.5,26-0.1c0.2,0.1,0.5,0.1,0.8,0.1c15.5-2.1,32.9-6,44.3-14.6c0.2-0.2,0.5-0.3,0.8-0.3c12.4-0.6,24.8,0.5,36.6,3.2
c0.5,0.1,1,0,1.3-0.3c2.6-2.4,5.2-4.7,7.8-7.1c0.6-0.6,1.6-0.5,2.1,0.1c2.4,2.9,5.6,5.4,9.3,7.2c0.8,0.4,1.7,0.1,2-0.7
c2.2-5.3,6.3-10.1,11.7-13.8c0.9-0.6,2-0.1,2.2,0.9c0.9,3.7,7.7,6.6,12.8,5.1c6.2-1.8,9.4-7.2,10.9-12.3c1.3-4.4,1.9-9.1,4.5-13
c0.5-0.8,1.8-1,2.4-0.2c3.4,4.4,10.2,7.1,16.8,6.5c6.5-0.5,12.3-4.3,14.5-9.1c0.4-0.9,1.5-1.1,2.3-0.5c16.5,13.5,33,26.9,49.5,40.4
c0.6,0.4,1.3,0.4,1.9,0c1.9-1.6,3.9-3.3,5.8-5c0.6-0.5,1.5-0.4,2,0.1c4.2,4.4,9.9,7.9,16.3,10.2c0.6,0.2,1.3,0,1.7-0.6
c1-1.4,1.9-2.8,2.9-4.2c0.3-0.4,0.7-0.6,1.1-0.6c8.3-0.6,16.6-1.2,25-1.9c2.3-0.2,4.9-0.4,6.7-1.6c2-1.2,2.8-3.3,3.5-5.2
c2.3-6.8,4.7-13.5,7-20.2c0.3-0.9,1.4-1.3,2.2-0.8c2.6,1.8,9.3,0.7,12.3-1.9c3.9-3.5,8.6-8.4,14-6.6c2.6,0.9,3.9,3.1,5.9,4.6
c1.6,1.3,4.5,1.9,6.3,1c0.4-0.2,0.8-0.2,1.2-0.1c4.7,1.9,10.4,4.4,14.3,5.8c5.6,2.1,12.1,4.2,17.8,2.3c2.7-0.9,4.9-2.6,7.5-3.6
c8.8-3.3,19.3,2.1,28.8,0.4c3.6-0.6,6.9-2.2,10.6-2.2c8,0.2,13.2,8,21.2,8c8.2,0,13.3-8.4,21.6-8.7c8-0.2,12.7,7,19.9,9.9
c7.9,3.2,17.7,0.3,25-3.7s13.7-9.2,22.1-11.6c2-0.5,4.1-0.9,6.1-0.6c0.4,0.1,0.8,0.2,1.6,0.3c0.9,0.1,1.7-0.6,1.7-1.5l0.1-66.4L0,0
L0.1,131.7z"
      ></path>{" "}
    </svg>
  );
};

export default Jagged;
